import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { DataService } from './data.service';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class ServiceWithAPIService {
  constructor(private http: HttpClient) {}

  private urlAPI: string = '/ws/';
  private accessKey = 'AKIARYIWK2I2XUANDI4Y';
  private secretKey = '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';
  private region = 'us-east-2';
  private service = 'execute-api';
  private apiKey = 'aF6WgmsmI3GfKOFEzsoRwCPRvtReOwvFFeQlvd9';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': this.apiKey, // Include x-api-key in the headers
  });
  private dataService: DataService;
  private showAllCustomers = false;
  private userCompanies: string[] = [];
  private CustomerList = [];
  private CustIdString: string = '';

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  private generateSignatureKey(dateStamp: string): string {
    const kDate = crypto.HmacSHA256(dateStamp, `AWS4${this.secretKey}`);
    const kRegion = crypto.HmacSHA256(this.region, kDate);
    const kService = crypto.HmacSHA256(this.service, kRegion);
    const kSigning = crypto.HmacSHA256('aws4_request', kService);
    return kSigning;
  }

  private generateSignature(stringToSign: string, dateStamp: string): string {
    const key = this.generateSignatureKey(dateStamp);
    return crypto.HmacSHA256(stringToSign, key).toString();
  }

  private createCanonicalRequest(
    httpMethod: string,
    canonicalUri: string,
    canonicalQuerystring: string,
    canonicalHeaders: string,
    signedHeaders: string,
    payload: string
  ): string {
    return `${httpMethod}\n${canonicalUri}\n${canonicalQuerystring}\n${canonicalHeaders}\n${signedHeaders}\n${crypto
      .SHA256(payload)
      .toString()}`;
  }

  private createStringToSign(
    canonicalRequestHash: string,
    dateStamp: string
  ): string {
    const shortDate = dateStamp.split('T')[0];
    const credentialScope = `${shortDate}/${this.region}/${this.service}/aws4_request`;
    return `AWS4-HMAC-SHA256\n${dateStamp}\n${credentialScope}\n${canonicalRequestHash}`;
  }

  private addSignatureToHeaders(
    headers: HttpHeaders,
    signature: string,
    dateStamp: string
  ) {
    return headers.append(
      'Authorization',
      `AWS4-HMAC-SHA256 Credential=${this.accessKey}/${dateStamp}/${this.region}/${this.service}/aws4_request, SignedHeaders=content-type;host;x-amz-date;x-amz-content-sha256, Signature=${signature}`
    );
  }

  ajaxFnGET<T>(
    functionAPI: string,
    parameters: { [key: string]: string | number }
  ): Observable<T> {
    // check if any of the values in the parameters object are strings of numbers separated by commas. If so, split them into arrays, sort them numerically, and rejoin them to create a sorted string of comma-separated numbers. Also, URI encode the string.
    Object.keys(parameters).forEach((key) => {
      const value = parameters[key];
      if (typeof value === 'string' && value.includes(',')) {
        parameters[key] = value
          .split(',')
          .map((v) => Number(v.trim())) // Convert to numbers
          .sort((a, b) => a - b) // Sort numerically
          .join('%2C');
      }
    });

    let params = new HttpParams({ fromObject: parameters });

    const dateStamp =
      new Date()
        .toISOString()
        .replace(/[-:]/g, '')
        .replace(/\.\d{3}/, '')
        .replace('Z', '') + 'Z';

    const canonicalUri = `${this.urlAPI}${functionAPI}`;

    const canonicalQuerystring = params
      .keys()
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params.get(key)!)}`
      )
      .join('&');

    const canonicalHeaders = `content-type:${this.headers.get(
      'Content-Type'
    )}\nhost:${
      window.location.host
    }\nx-amz-date:${dateStamp}\nx-amz-content-sha256:${crypto
      .SHA256('')
      .toString()}`;

    const signedHeaders = 'content-type;host;x-amz-date;x-amz-content-sha256';

    const canonicalRequest = this.createCanonicalRequest(
      'GET',
      canonicalUri,
      canonicalQuerystring,
      canonicalHeaders,
      signedHeaders,
      ''
    );

    const stringToSign = this.createStringToSign(
      crypto.SHA256(canonicalRequest).toString(),
      dateStamp
    );

    const signature = this.generateSignature(stringToSign, dateStamp);

    let updatedHeaders = this.addSignatureToHeaders(
      this.headers,
      signature,
      dateStamp
    );

    return this.http
      .get<T>(canonicalUri + '?' + canonicalQuerystring, {
        headers: updatedHeaders,
      })
      .pipe(
        catchError((error) => {
          console.error('API request error:', error);
          return this.handleError(error);
        })
      );
  }

  // Old ajax GET function - preserved for areas of the code that still use it.
  ajaxFunctionGET(
    functionAPI: string,
    parameters: string,
    callB,
    functionError = this.handleError
  ) {
    //let params = new HttpParams({ fromObject: parameters });
    let params = parameters;

    const dateStamp = new Date()
      .toISOString()
      .replace(/[:\-]|\.\d{3}/g, '')
      .slice(0, -1);
    const canonicalUri = `${this.urlAPI}${functionAPI}`;
    const canonicalQuerystring = params.toString();
    const canonicalHeaders = `content-type:${this.headers.get(
      'Content-Type'
    )}\nhost:${
      window.location.host
    }\nx-amz-date:${dateStamp}\nx-amz-content-sha256:${crypto
      .SHA256('')
      .toString()}`;
    const signedHeaders = 'content-type;host;x-amz-date;x-amz-content-sha256';

    const canonicalRequest = this.createCanonicalRequest(
      'GET',
      canonicalUri,
      canonicalQuerystring,
      canonicalHeaders,
      signedHeaders,
      ''
    );

    const stringToSign = this.createStringToSign(
      crypto.SHA256(canonicalRequest).toString(),
      dateStamp
    );

    const signature = this.generateSignature(stringToSign, dateStamp);

    let updatedHeaders = this.addSignatureToHeaders(
      this.headers,
      signature,
      dateStamp
    );
    console.log(parameters);
    this.http
      .get(this.urlAPI + functionAPI + '?' + parameters, {
        headers: updatedHeaders,
      })
      .subscribe({
        next: callB,
        error: functionError,
      });
  }

  /**
   * Fetches the list of customers from the backend, sorted alphabetically by name.
   * @returns An Observable of customer array sorted by name.
   */
  fetchCustomers(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.urlAPI}catalogs/customers`, { headers: this.headers })
      .pipe(
        map((result) => {
          return result.sort((a, b) => a.name.localeCompare(b.name));
        })
      );
  }

  /**
   * Fetches the list of customers from the backend, sorted alphabetically by name.
   * @returns An Observable of customer array sorted by name.
   */
  fetchUserInfo(username: string): Observable<any[]> {
    const functionAPI = 'catalogs/userinfo';
    const encodedUsername = username.replace('@', '_at_').replace('.', '_dot_');
    const parameters = { username: encodedUsername };

    return this.ajaxFnGET<any[]>(functionAPI, parameters).pipe(
      map((result) => {
        return result;
      })
    );
  }

  /**
   * Fetches shipment data based on a constructed URL.
   * @param getUrl The URL to fetch shipment data from.
   * @returns An Observable of the shipment data.
   */
  fetchShipments(getUrl: string): Observable<any> {
    console.log(`${this.urlAPI}shipments?${getUrl}`);
    return this.http
      .get<any>(`${this.urlAPI}shipments?${getUrl}`, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  sendRequestEmail(
    useremail: string,
    supportemail: string,
    ccemails: string[],
    emailSubject: string,
    emailBody: string
  ): Observable<any> {
    const body = JSON.stringify({
      useremail: useremail,
      supportemail: supportemail,
      ccemails: ccemails,
      emailSubject: emailSubject,
      emailBody: emailBody,
    });

    return this.ajaxFnPost<any>('catalogs/sendemail', body).pipe(
      catchError((error) => {
        console.error('Error sending email:', error);
        return throwError(() => new Error('Error, try again'));
      })
    );
  }

  /**
   * Performs a POST request to the specified API endpoint with the given body.
   * @param functionAPI The API endpoint to which the request is made.
   * @param body The body of the POST request, typically an object with key-value pairs.
   * @returns An Observable of type T, where T is the expected type of the API response.
   */
  ajaxFnPost<T>(functionAPI: string, body: any): Observable<T> {
    //let params = new HttpParams({ fromObject: parameters });
    let params = '';

    const url =
      functionAPI === 'ratingquote'
        ? '/ratingquote/'
        : `${this.urlAPI}${functionAPI}`;
    const dateStamp = new Date()
      .toISOString()
      .replace(/[:\-]|\.\d{3}/g, '')
      .slice(0, -1);
    const canonicalUri = `${this.urlAPI}${functionAPI}`;
    const canonicalQuerystring = params.toString();
    const canonicalHeaders = `content-type:${this.headers.get(
      'Content-Type'
    )}\nhost:${
      window.location.host
    }\nx-amz-date:${dateStamp}\nx-amz-content-sha256:${crypto
      .SHA256('')
      .toString()}`;
    const signedHeaders = 'content-type;host;x-amz-date;x-amz-content-sha256';

    const canonicalRequest = this.createCanonicalRequest(
      'POST',
      canonicalUri,
      canonicalQuerystring,
      canonicalHeaders,
      signedHeaders,
      ''
    );

    const stringToSign = this.createStringToSign(
      crypto.SHA256(canonicalRequest).toString(),
      dateStamp
    );

    const signature = this.generateSignature(stringToSign, dateStamp);

    let updatedHeaders = this.addSignatureToHeaders(
      this.headers,
      signature,
      dateStamp
    );

    return this.http
      .post<T>(url, body, { headers: updatedHeaders })
      .pipe(catchError(this.handleError));
  }

  // Old ajax POST function - preserved for areas of the code that still use it.
  ajaxFunctionPost(
    functionAPI: string,
    parameters,
    callB,
    functionError = this.handleError
  ) {
    let params = new HttpParams({ fromObject: parameters });

    const dateStamp = new Date()
      .toISOString()
      .replace(/[:\-]|\.\d{3}/g, '')
      .slice(0, -1);
    const canonicalUri = `${this.urlAPI}${functionAPI}`;
    const canonicalQuerystring = params.toString();
    const canonicalHeaders = `content-type:${this.headers.get(
      'Content-Type'
    )}\nhost:${
      window.location.host
    }\nx-amz-date:${dateStamp}\nx-amz-content-sha256:${crypto
      .SHA256('')
      .toString()}`;
    const signedHeaders = 'content-type;host;x-amz-date;x-amz-content-sha256';

    const canonicalRequest = this.createCanonicalRequest(
      'POST',
      canonicalUri,
      canonicalQuerystring,
      canonicalHeaders,
      signedHeaders,
      ''
    );

    const stringToSign = this.createStringToSign(
      crypto.SHA256(canonicalRequest).toString(),
      dateStamp
    );

    const signature = this.generateSignature(stringToSign, dateStamp);

    let updatedHeaders = this.addSignatureToHeaders(
      this.headers,
      signature,
      dateStamp
    );

    if (functionAPI == 'ratingquote') {
      // console.log('Rating Quote parameters: ', JSON.stringify(parameters));
      // console.log('Rating Quote headers: ', JSON.stringify(this.headers));
      this.http
        .post(`/ratingquote/`, parameters, { headers: updatedHeaders })
        .subscribe({
          next: (response) => {
            // console.log('Raw Response: ', response);
            callB(response);
          },
          error: (error) => {
            console.error('Error Response: ', error);
            functionError(error);
          },
        });
    } else {
      this.http
        .post(this.urlAPI + functionAPI, parameters, {
          headers: updatedHeaders,
        })
        .subscribe({
          next: callB,
          error: functionError,
        });
    }
  }
}
