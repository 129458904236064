// interstitial.component.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interstitial',
  templateUrl: './interstitial.component.html',
  styleUrls: ['./interstitial.component.scss']
})
export class InterstitialComponent implements OnInit {
  countdown: number = 10;
  private intervalId: any;

  constructor(private router: Router) {}

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.intervalId);
        this.redirectToNewUrl();
      }
    }, 1000);
  }

  redirectToNewUrl() {
    const hostname = window.location.hostname;
    console.log('Hostname:', hostname);
    let newUrl: string;

    if (hostname === 'fd.gfb.ai') {
      newUrl = 'https://app.freightdesk.ai';
    } else if (hostname === 'fddev.gfb.ai') {
      newUrl = 'https://qa.freightdesk.ai';
    } else if (hostname === 'fdpredev.gfb.ai') {
      newUrl = 'https://dev.freightdesk.ai';
    } else if (hostname === 'localhost') {
      newUrl = 'http://10.0.1.33:8100';
    } else {
      newUrl = 'https://app.freightdesk.ai';
    }

    window.location.href = newUrl;

  }
}
