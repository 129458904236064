// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-app {
  -webkit-user-select: text !important;
  user-select: text !important;
}

ion-app [contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

ion-app.force-statusbar-padding {
  --ion-safe-area-top: 20px;
}

@media (min-width: 1200px) {
  .fdIonHideMenu {
    display: none;
  }
}
@media (max-width: 1200px) {
  .fdIonShowButton {
    display: none;
  }
}
.content-wrapper {
  --padding-top: 56px;
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-bottom: 16px;
}

nav.navbar.navbar-default {
  margin-bottom: 0rem !important;
}

.fd-navbar-button {
  font-size: 1.3rem;
  --padding-start: 2rem;
  --padding-end: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EAGA,4BAAA;AACF;;AAEA;EACE,yBAAA;EAGA,iBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;EAAF;AACF;AAGA;EACE,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,sBAAA;AADF;;AAIA;EACE,8BAAA;AADF;;AAIA;EACE,iBAAA;EACA,qBAAA;EACA,mBAAA;AADF","sourcesContent":["ion-app {\n  -webkit-user-select: text !important;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: text !important;\n}\n\nion-app [contenteditable] {\n  -webkit-user-select: text;\n  -moz-user-select: text;\n  -ms-user-select: text;\n  user-select: text;\n}\n\nion-app.force-statusbar-padding {\n  --ion-safe-area-top: 20px;\n}\n\n@media (min-width: 1200px) {\n  .fdIonHideMenu {\n    display: none;\n  }\n}\n\n@media (max-width: 1200px) {\n  .fdIonShowButton {\n    display: none;\n  }\n}\n\n.content-wrapper {\n  --padding-top: 56px; // Adjust this value based on the height of your header\n  --padding-start: 16px;\n  --padding-end: 16px;\n  --padding-bottom: 16px;\n}\n\nnav.navbar.navbar-default {\n  margin-bottom: 0rem !important;\n}\n\n.fd-navbar-button {\n  font-size: 1.3rem;\n  --padding-start: 2rem;\n  --padding-end: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
