import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { UserInfo } from '../interfaces';
import { Observable, BehaviorSubject, catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { last, map } from 'rxjs/operators';
import { Console } from 'console';
import { ServiceWithAPIService } from './service-with-api.service';
// console.log(environment);
const poolData = environment.poolData;

// var userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root',
})
export class CognitoImplementationService {
  private userPool: CognitoUserPool;
  public loggedIn: boolean;
  private authState = new BehaviorSubject<boolean>(false);

  userInfo: UserInfo = {} as UserInfo;
  private userDetailsSubject = new BehaviorSubject<any>(this.userInfo);
  public userDetails$ = this.userDetailsSubject.asObservable();

  showDashboard: boolean = false;
  private showDashboardSubject = new BehaviorSubject<boolean>(false);

  showPricingPrograms: boolean = false;
  private showPricingProgramsSubject = new BehaviorSubject<boolean>(false);

  showCostStatus: boolean = false;
  private showCostStatusSubject = new BehaviorSubject<boolean>(false);

  showAllCustomers: boolean = false;
  private showAllCustomersSubject = new BehaviorSubject<boolean>(false);

  showInsOption: boolean = false;
  private showInsOptionSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private apiService: ServiceWithAPIService,
    private router: Router
  ) {
    this.userPool = new CognitoUserPool(poolData);
  }

  checkSession() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log('Session retrieval error', err);
          this.redirectToLogin();
        } else if (session.isValid()) {
          // console.log('Session is valid', session);
          this.handleValidSession(session);
        } else {
          this.redirectToLogin();
        }
      });
    } else {
      this.redirectToLogin();
    }
  }

  redirectToLogin() {
    this.logout();
    // Redirect to login page or prompt for login
    this.router.navigate(['/login']);
  }

  // Create a new function fetchUserDetails that takes the username as argument, access the user_info api, and returns the user details
  fetchUserDetails(username: string): Observable<any> {
    return this.apiService.fetchUserInfo(username).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error fetching user details:', error);
        // Return an observable of null when an error occurs
        return of(null);
      })
    );
  }

  private handleValidSession(session: any) {
    const cognitoInfo = session.getIdToken().payload;
    this.loggedIn = true;
    this.authState.next(true);

    // this.setDummyUserDetails();

    // Call fetchUserDetails and log the response to the console. Once the response is returned, execute the rest of the code in this function.
    this.fetchUserDetails(cognitoInfo['cognito:username']).subscribe(
      (response) => {
        if(response) {
          this.setUserDetails(response, cognitoInfo);
        } else {
          console.warn('Using default user details due to missing API response');
          this.setUserDetails(null, cognitoInfo);
        }
      }
    );
  }

  private clearSessionInfo() {
    this.loggedIn = false;
    this.authState.next(false);
    this.clearUserDetails();
    this.setShowDashboard(false);
    this.setShowPricingPrograms(false);
    this.setShowCostStatus(false);
    this.setShowAllCustomers(false);
    this.setShowInsOption(false);
    // console.log('Session is not valid');
  }

  signIn(username: string, password: string): Promise<void> {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const userData = {
      Username: username,
      Pool: this.userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    // console.log('Signing in user: ', username);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          console.log('Login success', session);
          this.handleValidSession(session);
          resolve();
        },
        onFailure: (err) => {
          console.error('Login failed', err);
          this.clearSessionInfo();
          reject(err);
        },
      });
    });
  }

  logout(): void {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      localStorage.clear();
    }
    this.clearSessionInfo();
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(this.loggedIn);
    });
  }

  getAuthenticatedUser() {
    return this.userPool.getCurrentUser();
  }

  forgottenPassword(userMail: string, successFun: any) {
    const userData = {
      Username: userMail,
      Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: successFun,
      onFailure: (err) => {
        // console.log(err);
      },
    });
  }

  confirmPassword(
    userMail: string,
    newPassword: string,
    verificationCode: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: userMail,
        Pool: this.userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure(err) {
          reject(err);
        },
        onSuccess() {
          resolve();
        },
      });
    });
  }

  changePassword(lastPass: string, newPass: string, answerFun) {
    // console.log('Changing password. Inputs: ', lastPass, newPass);
    const cognitoUser = this.userPool.getCurrentUser();

    const authenticationData = {
      Username: cognitoUser.getUsername(),
      Password: lastPass,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(lastPass, newPass, (err, result) => {
          if (err) {
            console.error('Password change error: ', err);
            answerFun(true, err.message || JSON.stringify(err));
          } else {
            // console.log('Password change success: ', result);
            answerFun(false, 'Password updated successfully');
          }
        });
      },
      onFailure: (err) => {
        console.error('Authentication error: ', err);
        answerFun(true, 'Current password is incorrect');
      },
    });
  }

  // Set up observables for user parameters
  isLoggedIn(): Observable<boolean> {
    return this.authState.asObservable();
  }

  checkLoggedIn(): boolean {
    return this.loggedIn;
  }

  setUserDetails(userinforeceived: any, cognitoInfo: any) {
    const givenName = cognitoInfo['given_name'] && cognitoInfo['given_name'].toLowerCase() !== 'none' ? cognitoInfo['given_name'] : '';
    const familyName = cognitoInfo['family_name'] && cognitoInfo['family_name'].toLowerCase() !== 'none' ? cognitoInfo['family_name'] : '';
    const full_name = `${givenName} ${familyName}`.trim();

    const groupInfo = userinforeceived?.group || {};
    const companyList = groupInfo['companies_list'] ? JSON.parse(groupInfo['companies_list']) : [];
    
    this.userInfo = {
      full_name: full_name,
      first_name: givenName,
      last_name: familyName,
      username: cognitoInfo['cognito:username'],
      email: cognitoInfo['email'],
      company: companyList[0] || null,
      companyList: companyList.length > 0 ? companyList : [],
      parent_company: groupInfo['parent_company'] && groupInfo['parent_company'].toLowerCase() !== 'none' ? groupInfo['parent_company'] : null,
      support_email: groupInfo['support_email'] && groupInfo['support_email'].toLowerCase() !== 'none' ? groupInfo['support_email'] : 'freight@freightdesk.ai',
      email_cc_list: groupInfo['email_cc_list'] && groupInfo['email_cc_list'].toLowerCase() !== 'none' ? JSON.parse(groupInfo['email_cc_list']) : null,
      permissionsList: []
    };

    if (userinforeceived?.group) {
      ['dashboards', 'pricing_programs', 'costs', 'customers', 'insurance'].forEach(permission => {
        if (groupInfo[permission]) {
          this.userInfo.permissionsList.push(permission.charAt(0).toUpperCase() + permission.slice(1));
        }
      });
    } else {
      this.userInfo.permissionsList = ['Insurance'];
    }

    this.setShowDashboard(groupInfo['dashboards'] || false);
    this.setShowPricingPrograms(groupInfo['pricing_programs'] || false);
    this.setShowCostStatus(groupInfo['costs'] || false);
    this.setShowAllCustomers(groupInfo['customers'] || false);
    this.setShowInsOption(groupInfo['insurance'] || true);

    this.userDetailsSubject.next(this.userInfo);
    console.log('User details set: ', this.userInfo);
  }

  setDummyUserDetails() {
    const givenName = 'John'; //userinforeceived['given_name'] && userinforeceived['given_name'].toLowerCase() !== 'none' ? userinforeceived['given_name'] : '';
    const familyName = 'Gallo'; //userinforeceived['family_name'] && userinforeceived['family_name'].toLowerCase() !== 'none' ? userinforeceived['family_name'] : '';
    const full_name = 'John Gallo'; //`${givenName} ${familyName}`.trim();
    const groupInfo = null; //userinforeceived['group'];
    const companyList = '["UNICARGO USA", "UNICARGO FREIGHT", "UNICARGO ISRAEL LTD", "SOLOGISTX", "SOLOGISTX LLC"]'; //JSON.parse(groupInfo['companies_list']);
    
    const newDetails = {
      full_name: full_name,
      first_name: givenName,
      last_name: familyName,
      username: 'jgallo@unicargoscs.com', //userinforeceived['username'],
      email: 'jgallo@unicargoscs.com', //userinforeceived['email'],
      company: companyList[0],
      companyList: companyList,
      parent_company: 'UNICARGO USA', //groupInfo['parent_company'] && groupInfo['parent_company'].toLowerCase() !== 'none' ? groupInfo['parent_company'] : null,
      support_email: 'freight@unicargoscs.com', //groupInfo['support_email'] && groupInfo['support_email'].toLowerCase() !== 'none' ? groupInfo['support_email'] : null,
      email_cc_list: [], //groupInfo['email_cc_list'] && groupInfo['email_cc_list'].toLowerCase() !== 'none' ? JSON.parse(groupInfo['email_cc_list']) : [],
      permissionsList: ['Insurance']
    };

    this.setShowDashboard(false);
    this.setShowPricingPrograms(false);
    this.setShowCostStatus(false);
    this.setShowAllCustomers(false);
    this.setShowInsOption(true);

    if (JSON.stringify(this.userInfo) !== JSON.stringify(newDetails)) {
      this.userDetailsSubject.next(this.userInfo);
    }
    // console.log('User details set: ', this.userDetails);
  }
  
  clearUserDetails() {
    this.userInfo = {
      first_name: '',
      last_name: '',
      full_name: '',
      username: '',
      email: '',
      company: '',
      companyList: null,
      parent_company: '',
      support_email: '',
      email_cc_list: [],
      permissionsList: null,
    };
    this.userDetailsSubject.next(this.userInfo);
    // console.log('User details cleared: ', this.userDetails);
  }

  getUserDetails(): Observable<any> {
    return this.userDetailsSubject.asObservable();
  }

  setShowDashboard(showDashboard: boolean) {
    if (this.showDashboard !== showDashboard) {
      this.showDashboard = showDashboard;
      this.showDashboardSubject.next(showDashboard);
    }
    // console.log('Dashboard set to: ', showDashboard);
  }

  showDash(): Observable<boolean> {
    return this.showDashboardSubject.asObservable();
  }

  setShowCostStatus(showCostStatus: boolean) {
    if (this.showCostStatus !== showCostStatus) {
      this.showCostStatus = showCostStatus;
      this.showCostStatusSubject.next(showCostStatus);
    }
    // console.log('Cost status set to: ', showCostStatus);
  }

  showCosts(): Observable<boolean> {
    return this.showCostStatusSubject.asObservable();
  }

  setShowAllCustomers(showAllCustomers: boolean) {
    if (this.showAllCustomers !== showAllCustomers) {
      this.showAllCustomers = showAllCustomers;
      this.showAllCustomersSubject.next(showAllCustomers);
    }
    // console.log('Show all customers set to: ', showAllCustomers);
  }

  showAllCust(): Observable<boolean> {
    return this.showAllCustomersSubject.asObservable();
  }

  setShowPricingPrograms(showPricingPrograms: boolean) {
    if (this.showPricingPrograms !== showPricingPrograms) {
      this.showPricingPrograms = showPricingPrograms;
      this.showPricingProgramsSubject.next(showPricingPrograms);
    }
    // console.log('Pricing programs set to: ', showPricingPrograms);
  }

  showPP(): Observable<boolean> {
    return this.showPricingProgramsSubject.asObservable();
  }

  setShowInsOption(showInsOption: boolean) {
    if (this.showInsOption !== showInsOption) {
      this.showInsOption = showInsOption;
      this.showInsOptionSubject.next(showInsOption);
    }
    // console.log('Insurance option set to: ', showInsOption);
  }

  showInsuranceOption(): Observable<boolean> {
    return this.showInsOptionSubject.asObservable();
  }
}
