// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* interstitial.component.css */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adjust the gap between elements as needed */
  margin-bottom: 20px; /* Space before the text */
}

.logo {
  /* Adjust the size of the logos as needed */
  width: auto; /* Example size */
  height: 50px;
}

.arrow {
  font-size: 24px; /* Adjust the size of the arrow as needed */
}`, "",{"version":3,"sources":["webpack://./src/app/components/interstitial/interstitial.component.scss"],"names":[],"mappings":"AAAA,+BAAA;AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA,EAAA,8CAAA;EACA,mBAAA,EAAA,0BAAA;AACJ;;AAEA;EACI,2CAAA;EACA,WAAA,EAAA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA,EAAA,2CAAA;AACJ","sourcesContent":["/* interstitial.component.css */\n.logo-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px; /* Adjust the gap between elements as needed */\n    margin-bottom: 20px; /* Space before the text */\n}\n\n.logo {\n    /* Adjust the size of the logos as needed */\n    width: auto; /* Example size */\n    height: 50px;\n}\n\n.arrow {\n    font-size: 24px; /* Adjust the size of the arrow as needed */\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
