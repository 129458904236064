import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ElementRef, ViewChild } from '@angular/core';
import { CognitoImplementationService } from 'src/app/services/cognito-implementation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public messageSessionActions: string;
  public showDashboard: boolean = false;
  public showMobileMenu: boolean = false;
  public userGroups: string[];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private el: ElementRef,
    public auth: CognitoImplementationService
  ) {
    console.log('NavbarComponent: constructor');
  }

  ngOnInit() {
    this.subscriptions.push(
      this.auth.showDash().subscribe((showDashboard: boolean) => {
        this.showDashboard = showDashboard;
      }),
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.showMobileMenu = false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/index-home']);
  }
}
