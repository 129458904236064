import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CognitoImplementationService } from './services/cognito-implementation.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  public useremail: string = '';

  constructor(
    private router: Router,
    public auth: CognitoImplementationService,
    private menuController: MenuController
  ) {}

  ngOnInit(): void {
    this.auth.checkSession();
    this.checkDomainAndRedirect();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.trackPageView(event.urlAfterRedirects);
      }
    });
    this.auth.getUserDetails().subscribe((userDetails) => {
        this.setHSEmail(userDetails['email']);
    });
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/index-home']);
  }

  closeMenu() {
    this.menuController.close()
  }

  checkDomainAndRedirect() {
    const hostname = window.location.hostname;
    // Example: Redirect if the user is coming from "oldwebsite.com"

    if (['fd.gfb.ai', 'fddev.gfb.ai', 'fdpredev.gfb.ai', 'localhost'].includes(hostname)) {
      // Use Angular Router to navigate to the Interstitial Component
      // This assumes you've set up routing to your Interstitial Component
      this.router.navigate(['/interstitial']);
    }
    // Otherwise, continue as normal
  }

  setHSEmail(email: string) {
    const _hsq = (window as any)._hsq = (window as any)._hsq || [];
    _hsq.push(['identify',{
      email: email,
    }]);
  }

  trackPageView(url: string) {
    // console.log('Tracking page view:', url);
    const _hsq = (window as any)._hsq = (window as any)._hsq || [];
    _hsq.push(['setPath', url]);
    _hsq.push(['trackPageView']);
  }
}