// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 768px) {
  .fdIonHideMenu {
    display: none;
  }
}
@media (max-width: 768px) {
  .fdIonShowButton {
    display: none;
  }
}
nav.navbar.navbar-default {
  margin-bottom: 0rem !important;
}

.fd-navbar-button {
  font-size: 1.3rem;
  --padding-start: 3rem;
  --padding-end: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACI;IACE,aAAA;EACJ;AACF;AAEA;EACI;IACE,aAAA;EAAJ;AACF;AAGA;EACI,8BAAA;AADJ;;AAIA;EACI,iBAAA;EACA,qBAAA;EACA,mBAAA;AADJ","sourcesContent":["@media (min-width: 768px) {\n    .fdIonHideMenu {\n      display: none;\n    }\n}\n\n@media (max-width: 768px) {\n    .fdIonShowButton {\n      display: none;\n    }\n}\n\nnav.navbar.navbar-default {\n    margin-bottom: 0rem !important;\n}\n\n.fd-navbar-button {\n    font-size: 1.3rem;\n    --padding-start: 3rem;\n    --padding-end: 3rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
